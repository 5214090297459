import * as React from "react"
import { useState, useEffect } from "react"
import { Helmet } from "react-helmet"

import Breadcrumbs from "../../../components/breadcrumbs"
import Hero from "../../../components/hero"
import ImageTeaser from "../../../components/image-teaser"
import PageHeader from "../../../components/page-header"
import SectionHeader from "../../../components/section-header"
import Footer from "../../../components/footer"
import QROverlay from "../../../components/qr-overlay"

const imgMajBritt = require("../../../images/utan-demokratiska-rattigheter/maj-britt-portratt.jpg")
const imgJohanDimitri = require("../../../images/utan-demokratiska-rattigheter/johan-dimitri-portratt.png")
const imgEster = require("../../../images/utan-demokratiska-rattigheter/ester-portratt.png")

import "../../../scss/site.scss"
//import StickyCta from "../../../components/sticky-cta"

const WithoutDemocraticRights = props => {
  const [showOverlay, setShowOverlay] = useState(false)
  const [heading, setHeading] = useState(null)
  const [text, setText] = useState(null)
  const [linkText, setLinkText] = useState(null)

  useEffect(() => {
    if (showOverlay) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = ""
    }
    return () => {
      document.body.style.overflowY = ""
    }
  }, [showOverlay])

  const handleShowOverlay = (heading, text, linkText) => {
    if (heading) setHeading(heading)
    if (text) setText(text)
    if (linkText) setLinkText(linkText)
    setShowOverlay(!showOverlay)
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Utan Demokratiska Rättigheter | Demokrati Pågår </title>
        <link
          rel="canonical"
          href="https://www.demokratipagar.se/historiska-perspektiv/utan-demokratiska-rattigheter"
        ></link>
        <meta property="og:site_name" content="Demokrati Pågår"></meta>
        <meta property="og:type" content="article"></meta>
        <meta
          property="og:url"
          content="https://www.demokratipagar.se/historiska-perspektiv/utan-demokratiska-rattigheter"
        ></meta>
        <meta
          property="og:title"
          content="Utan Demokratiska Rättigheter"
        ></meta>
        <meta name="description" content="" />
        <script
          type="text/javascript"
          src="https://www.browsealoud.com/plus/scripts/3.1.0/ba.js"
          crossorigin="anonymous"
          integrity="sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ=="
        ></script>
      </Helmet>
      <div className="c-body">
        <PageHeader />
        <div
          role="main"
          id="main"
          className="o-main c-main-background c-main-background--historiska-perspektiv"
        >
          <Breadcrumbs
            modifier="indent"
            crumbs={props.pageContext.breadcrumb}
          />
          <Hero
            buttonLink="handledning"
            buttonText="Till läraren"
            modifier="has-breadcrumbs"
            title="Utan demokratiska rättigheter"
          >
            <p>
              Trots att vi brukar säga att Sverige blev en demokrati 1921 var
              vissa människor utan demokratiska rättigheter. Varför var det så?
              Och vad fick det för konsekvenser?
            </p>
            <p>
              Lyssna på berättelserna om Maj-Britt Johansson, Johan Dimitri
              Taikon och Ester Henning. Låt eleverna titta på källorna och
              diskutera berättelserna. Du kan dela övningen med dina elever.
            </p>
          </Hero>
          <section className="c-content-section" aria-labelledby="utforska">
            <SectionHeader id="utforska" text="Utforska" />
            <div className="container c-content-section__container">
              <div className="c-content-section__content c-content-section__content--fluid">
                <div className="c-content-section__row row">
                  <div className="col-xs-12 col-md-4">
                    <ImageTeaser
                      qr="yes"
                      modifier="small"
                      img={imgMajBritt}
                      imgAlt="Porträtt på Maj-Britt Johansson"
                      title="Maj-Britt Johansson"
                      listheading="Innehåller:"
                      segments={[
                        { text: "Reflektionsfrågor" },
                        { text: "Bildberättelse, ca 4:30 min" },
                        { text: "Källor" },
                      ]}
                      to="maj-britt-johansson"
                      linkButtonText="Till Maj-Britts sida"
                      buttonText="Länk och QR-kod"
                      onClick={() => {
                        handleShowOverlay(
                          "Utan demokratiska rättigheter: Maj-Britt Johansson",
                          "Öppna med QR-kod eller skriv in länk:",
                          "http://bit.ly/majbritt"
                        )
                      }}
                    >
                      <p>
                        Levde 1929-1985. Maj-Britt kom från ett fattigt hem. Hon
                        ansågs vara sinnesslö och blev steriliserad så att hon
                        aldrig skulle kunna få egna barn. Hon levde hela sitt
                        liv inlåst på olika sjukhus.
                      </p>
                    </ImageTeaser>
                  </div>
                  <div className="col-xs-12 col-md-4">
                    <ImageTeaser
                      qr="yes"
                      modifier="small"
                      img={imgJohanDimitri}
                      imgAlt="Porträtt på Johan Dimitri Taikon"
                      title="Johan Dimitri Taikon"
                      listheading="Innehåller:"
                      segments={[
                        { text: "Reflektionsfrågor" },
                        { text: "Bildberättelse, ca 3:30 min" },
                        { text: "Källor" },
                      ]}
                      to="johan-dimitri-taikon"
                      linkButtonText="Till Johans sida"
                      buttonText="Länk och QR-kod"
                      onClick={() => {
                        handleShowOverlay(
                          "Utan demokratiska rättigheter: Johan Dimitri Taikon",
                          "Öppna med QR-kod eller skriv in länk:",
                          "bit.ly/johandim"
                        )
                      }}
                    >
                      <p>
                        Levde 1879-1950. Johan fick inte gå i skolan och kunde
                        aldrig rösta i demokratiska val på grund av att han var
                        rom. Johan ville förändra och kämpade för att romer
                        skulle ha samma rättigheter som alla andra i Sverige.
                      </p>
                    </ImageTeaser>
                  </div>
                  <div className="col-xs-12 col-md-4">
                    <ImageTeaser
                      qr="yes"
                      modifier="small"
                      img={imgEster}
                      imgAlt="Porträtt på Ester Henning"
                      title="Ester Henning"
                      listheading="Innehåller:"
                      segments={[
                        { text: "Reflektionsfrågor" },
                        { text: "Bildberättelse, ca 4:30 min" },
                        { text: "Källor" },
                      ]}
                      to="ester-henning"
                      linkButtonText="Till Esters sida"
                      buttonText="Länk och QR-kod"
                      onClick={() => {
                        handleShowOverlay(
                          "Utan demokratiska rättigheter: Ester Henning",
                          "Öppna med QR-kod eller skriv in länk:",
                          "bit.ly/esterhen"
                        )
                      }}
                    >
                      <p>
                        Levde 1887-1985. Ester var konstnär som kom från ett
                        fattigt hem. Som ung blev hon sjuk i sjukdomen
                        Schizofreni. Ester levde sen resten av sitt liv inlåst
                        på olika sjukhus.
                      </p>
                    </ImageTeaser>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
        {showOverlay && (
          <QROverlay
            show={showOverlay}
            heading={heading}
            text={text}
            linkText={linkText}
            toggleShow={handleShowOverlay}
          />
        )}
        {/*<StickyCta />*/}
      </div>
    </>
  )
}

export default WithoutDemocraticRights
